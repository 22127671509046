import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, from, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize, tap } from 'rxjs/operators';
import { ToolsService } from '../providers/tools.service';
import { USER_SESSION_EXPIRATION_TIME } from '../consts/consts';
import { getAuth } from 'firebase/auth';
import { AppService } from '../providers/app.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly localStorageKey = 'token';
  private isRefreshTokenInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly skipUrls = [
    '/collection/reminder-messages/schedule/validation',
    '/graphs',
    '/debt-collection/reminder-messages-pools',
    '/renewals/reminder-messages-pools/',
    '/admin/organizations',
    '/companies/phone-number',
    '/organizations/update-organization',
  ]; // Add the URLs you want to skip here

  constructor(
    private router: Router,
    private tools: ToolsService,
    private appService: AppService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.get('Authorization')) {
      request = this.addAuthHeader(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) =>
        from(getAuth().authStateReady()).pipe(
          switchMap(() => {
            if (this.skipUrls.some((url) => request.url.includes(url))) {
              return throwError(error); // throw the whole error object (not only the message)
            }
            if (!this.isAuthError(error.status) || !getAuth().currentUser) {
              this.handleAuthError(error);
              return throwError(error.message);
            } else {
              this.checkSessionExpiration();
              if (this.isRefreshTokenInProgressSubject.getValue()) {
                return this.waitToRefreshTokenAndResendRequest(request, next);
              } else {
                this.isRefreshTokenInProgressSubject.next(true);
                return this.refreshIdTokenAndResendRequest(request, next);
              }
            }
          })
        )
      )
    );
  }

  private waitToRefreshTokenAndResendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isRefreshTokenInProgressSubject.pipe(
      filter((inProgress: boolean) => inProgress === false),
      take(1),
      switchMap(() =>
        next.handle(this.addAuthHeader(request)).pipe(
          catchError((error: HttpErrorResponse) => {
            this.handleAuthError(error);
            return throwError(error.message);
          })
        )
      )
    );
  }

  private refreshIdTokenAndResendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(getAuth().currentUser.getIdToken(true)).pipe(
      tap((newToken: string) => {
        localStorage.setItem('token', newToken);
      }),
      finalize(() => this.isRefreshTokenInProgressSubject.next(false)),
      switchMap(() =>
        next.handle(this.addAuthHeader(request)).pipe(
          catchError((error: HttpErrorResponse) => {
            this.handleAuthError(error);
            return throwError(error.message);
          })
        )
      )
    );
  }

  private isUnAuthorizedError(errorStatus: number): boolean {
    return errorStatus === 401;
  }

  private isForbiddenError(errorStatus: number): boolean {
    return errorStatus === 403;
  }

  private isAuthError(errorStatus: number): boolean {
    return this.isUnAuthorizedError(errorStatus) || this.isForbiddenError(errorStatus);
  }

  private handleAuthError(error: HttpErrorResponse): void {
    if (this.isUnAuthorizedError(error.status)) {
      localStorage.clear();
      this.router.navigateByUrl('/account/login');
    }
    if (this.isForbiddenError(error.status)) {
      this.tools.toast('User is not permitted', 'Close');
    }
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const setHeaders = {
      Authorization: `Bearer ${localStorage.getItem(this.localStorageKey)}`,
    };

    return request.clone({
      setHeaders,
    });
  }

  private checkSessionExpiration(): void {
    const lastLogin = localStorage.getItem('lastLogin') || 0;
    if (!lastLogin || Date.now() - Number(lastLogin) > USER_SESSION_EXPIRATION_TIME) {
      this.tools.doLogout(); // Logout user
    }
  }
}
