import { Routes } from '@angular/router';
import { AccountGuard } from '../guards/account.guard';
import { MainGuard } from '../guards/main.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account.routes').then((m) => m.ACCOUNT_ROUTES),
    canActivate: [AccountGuard],
  },
  {
    path: 'main',
    loadChildren: () => import('./main.routes').then((m) => m.MAIN_ROUTES),
    canActivateChild: [MainGuard],
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import('../components/unauthorized/unauthorized.component').then((m) => m.UnauthorizedComponent),
  },
  { path: '**', redirectTo: 'unauthorized', pathMatch: 'full' },
  { path: '', redirectTo: 'unauthorized', pathMatch: 'full' },
];
