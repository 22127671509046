import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { EnvConfig } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public ENV_CONFIG: EnvConfig = {} as EnvConfig;
  constructor(private remoteConfig: AngularFireRemoteConfig) {}

  public initConfig = async (): Promise<void> => {
    if (!Object.keys(this.ENV_CONFIG).length) {
      await this.remoteConfig.fetchAndActivate();
      const params = await this.remoteConfig.getAll();
      for (const key in params) {
        this.ENV_CONFIG[key] = params[key].asString();
      }
    }
  };
}
