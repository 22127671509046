import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { AccountGuard } from './app/guards/account.guard';
import { MainGuard } from './app/guards/main.guard';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { FileService } from './app/providers/file.service';
import { HttpService } from './app/providers/http.service';
import { MainService } from './app/providers/main.service';
import { ToolsService } from './app/providers/tools.service';
import { APP_ROUTES } from './app/routes/app.routes';
import { environment } from './environments/environment';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      NgxMaskModule.forRoot(),
      MonacoEditorModule.forRoot(),
      AngularFireModule.initializeApp(environment.FIREBASE_CREDENTIAL),
      AngularFireRemoteConfigModule,
      BrowserModule
    ),
    ToolsService,
    MainService,
    FileService,
    HttpService,
    AccountGuard,
    MainGuard,
    { provide: SETTINGS, useValue: { minimumFetchIntervalMillis: 3600000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(APP_ROUTES),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
